<template>
  <div class="page">
    <div v-if="ready" class="container-fluid">
      <order-by/>
      <div class="screen list">
        <div class="row">
          <div class="col-12 col-lg-4" v-if="isOwner()">
            <div class="item screen__upload" id="drop-area" ref="dropArea">

              <form class="form" @submit.prevent="createNew($event)">
                <div class="form-group">
                  <label>Screen name</label>
                  <input type="text" class="form-control" v-model="newTitle">
                </div>
                <div class="form-group">
                  <input id="file" type="file" @change="uploadFile" ref="file" hidden>
                  <label for="file" class="btn btn-info btn-block">Select Image</label>
                </div>
                <div id="preview"></div>
                <div class="form-group">
                  <button type="submit" class="btn btn-primary btn-block">Upload</button>
                </div>
              </form>

            </div>
          </div>
          <div class="col-12 col-lg-4 parent" v-for="(screen,index) in screens" v-bind:key="index">
            <div class="actions" v-if="isOwner()">
              <button class="btn btn-info btn-sm" @click="edit(screen)">Edit</button>
              <button class="btn btn-danger btn-sm" @click="deleteItem(screen.id)">Delete</button>
            </div>
            <router-link :to="'/project/'+projectId+'/flow/'+flowId+'/screen/'+screen.id" class="item">
              <div class="image"><img :src="screensPath +screen.image" alt=""></div>
              <div class="text">
                <div class="item__title">{{ screen.title }}</div>
                <div class="item__date">{{ screen.created_at | moment('calendar') }}</div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <div class="modal" tabindex="-1" v-if="showEditModal">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Edit Title</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                      @click="showEditModal = false">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="form-group">
                <input type="text" class="form-control" v-model="newTitle">
                <small class="form-text text-muted">Please choose title.</small>
              </div>
              <div class="form-group">
                <label for="flows">Flow</label>
                <select class="form-control" id="flows" v-model="currentFlowId">
                  <option :value="item.id" v-for="item in flows" :key="item.id">{{item.title}}</option>
                </select>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="showEditModal = false">
                Close
              </button>
              <button type="button" class="btn btn-primary" @click="update()">Save changes</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


import ApiService from "../services/api";
import OrderBy from "../components/OrderBy";

export default {
  name: "project",
  components: {OrderBy},
  data() {
    return {
      screensPath: window.screensPath,
      ready: false,
      newModal: false,
      projectId: this.$route.params.projectId,
      flowId: this.$route.params.flowId,
      newTitle: '',
      currentFlowId: this.$route.params.flowId,
      flow: {},
      screens: [],
      flows: [],
      image: null,
      showEditModal: false,
      selectedItemId: '',
    }
  },
  mounted () {
    this.$gtag.pageview('flow_detail')
  },
  computed: {
    user() {
      return this.$store.getters['user'].data
    },
    orderBy() {
      return this.$store.getters.orderBy;
    }
  },
  methods: {
    isOwner() {
      return this.user.permissions[this.projectId]
    },
    getData() {
      const self = this;
      const $route = self.$route;
      const projectId = $route.params.projectId;
      const flowId = $route.params.flowId;
      self.$store.commit('SET_LOADING', true)
      ApiService.get('project/' + projectId + '/flow/' + flowId + '/screens').then((res) => {
        self.ready = true;
        self.screens = res.data.data
        self.flow = res.data.flow
        self.flows = res.data.flows
        this.$store.commit('SET_LOADING', false)
        this.$store.commit('SET_BREADCRUMBS', {project: res.data.project, flow: res.data.flow})
        this.orderByItems()
      })

    },
    uploadFile() {
      this.image = this.$refs.file.files[0];
      let reader = new FileReader()
      reader.readAsDataURL(this.$refs.file.files[0])
      console.log(reader)
      reader.onloadend = function () {
        let img = document.createElement('img')
        img.src = reader.result;
        document.getElementById('preview').innerHTML = '';
        document.getElementById('preview').append(img)
      }
    },
    createNew() {
      const self = this;
      const $route = self.$route;
      const projectId = $route.params.projectId;
      const flowId = $route.params.flowId;


      self.$store.commit('SET_LOADING', true)

      let params = new FormData();
      params.append('title', self.newTitle);
      params.append('image', self.image);

      ApiService.post('project/' + projectId + '/flow/' + flowId + '/screen', params).then(() => {
        self.getData();
        self.newModal = false;
        self.newTitle = '';
        document.getElementById('preview').innerHTML = '';
        self.$gtag.event('create', { method: 'screen' })
      }).catch((error) => {
        self.$swal.fire({
          html: '<br/>' + error.response.data.error,
        })
        self.$store.commit('SET_LOADING', false)
      })

    },
    deleteItem(id) {
      const self = this;
      self.$swal.fire({
        title: 'Are you sure?',
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          self.$store.commit('SET_LOADING', true)
          ApiService.delete('project/' + self.$route.params.projectId + '/flow/' + self.$route.params.flowId + '/screen/' + id).then(() => {
            self.getData();
            self.$gtag.event('delete', { method: 'screen' })
          })
        }
      })

    },
    edit(item) {
      this.showEditModal = true
      this.newTitle = item.title;
      this.selectedItemId = item.id;
    },
    update() {
      const self = this;
      const params = new URLSearchParams();
      params.append('title', self.newTitle);
      params.append('flow_id', parseInt(self.currentFlowId));
      self.$store.commit('SET_LOADING', true)
      ApiService.post('project/' + self.$route.params.projectId + '/flow/' + self.$route.params.flowId + '/screen/' + self.selectedItemId, params).then(() => {
        self.getData();
        self.newTitle = '';
        self.currentFlowId = this.$route.params.flowId;
        self.showEditModal = false;
        self.selectedItemId = null;
        self.$store.commit('SET_LOADING', false)
        self.$gtag.event('update', { method: 'screen' })
      }).catch((error) => {
        self.$swal.fire({
          html: '<br/>' + error.response.data.error,
        })
        self.$store.commit('SET_LOADING', false)
      })
    },
    orderByItems() {
      const self = this;
      if (self.orderBy === 'asc') {
        self.screens = JSON.parse(JSON.stringify(self.screens)).sort(function (a, b) {
          return new Date(a.created_at).getTime() - new Date(b.created_at)
        });
      } else {
        self.screens = JSON.parse(JSON.stringify(self.screens)).sort(function (a, b) {
          return new Date(b.created_at).getTime() - new Date(a.created_at)
        });
      }
    },
    initDropzone(){
      const self = this;

      let dropArea = self.$refs.dropArea


      ;['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
        dropArea.addEventListener(eventName, preventDefaults, false)
        document.body.addEventListener(eventName, preventDefaults, false)
      })


      ;['dragenter', 'dragover'].forEach(eventName => {
        dropArea.addEventListener(eventName, highlight, false)
      })

      ;['dragleave', 'drop'].forEach(eventName => {
        dropArea.addEventListener(eventName, unhighlight, false)
      })


      dropArea.addEventListener('drop', handleDrop, false)

      function preventDefaults(e) {
        e.preventDefault()
        e.stopPropagation()
      }

      function highlight() {
        dropArea.classList.add('highlight')
      }

      function unhighlight() {
        dropArea.classList.remove('active')
      }

      function handleDrop(e) {
        var dt = e.dataTransfer
        var files = dt.files

        handleFiles(files)
      }


      function handleFiles(files) {
        files = [...files]
        files.forEach(previewFile)
      }

      function previewFile(file) {
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onloadend = function () {
          let img = document.createElement('img')
          img.src = reader.result
          document.getElementById('preview').innerHTML = '';
          document.getElementById('preview').append(img)
        }
      }
    }
  },
  created() {
    const self = this;
    self.$store.commit('SET_LOADING', true)
    self.getData();
  },
  watch: {
    orderBy() {
      this.orderByItems()
    },
    ready(value) {
      if (value) {
        this.$nextTick(() => {
          this.initDropzone()
        })
      }
    }
  }
}
</script>

<style lang="scss">
#drop-area {
  border: 2px dashed #ccc;
  border-radius: 5px;

}

#drop-area.highlight {
  border-color: #7954f3;
}

#preview {
  img {
    max-width: 150px;
    max-height: 100px;
    margin-bottom: 10px;
  }
}
</style>
