<template>
  <div class="page screen-page" v-if="ready">
    <div class="row">
      <div class="col-4 d-none d-md-block">
        <div class="screen">
          <div class="image card">
            <div class="parent" @click="isOwner() ? clicked($event) : ''">
              <img :src="screensPath + screen.image" alt="">
              <div id="findings" class="findings" ref="findings">
                <div class="new-finding-pin"
                     v-if="showNewFinding"
                     :style="'left:'+newFinding.axis_x+'%; top:'+newFinding.axis_y+'%;'"></div>
                <template v-if="!hidePins">
                           <span class="finding" v-for="(finding,index) in findings" v-bind:key="index"
                                 v-bind:class="{ active: selectedFindingIndex === index }"
                                 :style="'left:'+finding.axis_x+'%; top:'+finding.axis_y+'%;'"
                                 @mousedown="selectedFindingIndex = index">{{ index + 1 }}</span>
                </template>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-4" v-if="isOwner()">
        <div class="card">
          <form v-if="showNewFinding">
            <div class="form-group">
              <label for="category">Category</label>
              <select class="form-control" id="category" v-model="newFinding.criteria_id">
                <option :value="category.id" v-for="(category,index) in categories" :key="'category-'+index">
                  {{ category.value }}
                </option>
              </select>
            </div>

            <div class="form-group">
              <label for="category-status">Category Status</label>
              <select class="form-control" id="category-status" v-model="newFinding.is_good">
                <option value="1">Good</option>
                <option value="0">Bad</option>
              </select>
            </div>

            <div class="form-group">
              <label for="severity">Severity of Issue</label>
              <select class="form-control" id="severity" v-model="newFinding.severity">
                <option value="1">High</option>
                <option value="2">Mid</option>
                <option value="3">Low</option>
              </select>
            </div>

            <div class="form-group">
              <label for="severity">Effort</label>
              <select class="form-control" id="effort" v-model="newFinding.effort">
                <option value="0">None</option>
                <option value="1">High</option>
                <option value="2">Mid</option>
                <option value="3">Low</option>
              </select>
            </div>

            <div class="form-group">
              <label for="exampleFormControlTextarea1">Finding</label>
              <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"
                        v-model="newFinding.finding"></textarea>
            </div>

            <div class="form-group">
              <label for="exampleFormControlTextarea2">Recommendation</label>
              <textarea class="form-control" id="exampleFormControlTextarea2" rows="3"
                        v-model="newFinding.recommendation"></textarea>
            </div>
            <button type="button" class="btn btn-success btn-block" @click="createNewFinding()">Add Finding</button>
          </form>
          <div v-else>
            <blockquote class="blockquote text-center">
              <p class="mb-0">Please select an area by clicking on the image on the left.</p>
              <footer class="blockquote-footer">The dots can overlap</footer>
            </blockquote>
          </div>
        </div>
      </div>
      <div class="col-4" v-if="isOwner()">
        <div class="card">
          <form v-if="showEditFinding">
            <div class="form-group">
              <label for="category">Category</label>
              <select class="form-control" v-model="newFinding.criteria_id">
                <option :value="category.id" v-for="(category,index) in categories" :key="'category-'+index">
                  {{ category.value }}
                </option>
              </select>
            </div>

            <div class="form-group">
              <label for="category-status">Category Status</label>
              <select class="form-control" id="category-status" v-model="newFinding.is_good">
                <option value="true">Good</option>
                <option value="false">Bad</option>
              </select>
            </div>

            <div class="form-group">
              <label for="severity">Severity of Issue</label>
              <select class="form-control" id="severity" v-model="newFinding.severity">
                <option value="1">High</option>
                <option value="2">Mid</option>
                <option value="3">Low</option>
              </select>
            </div>

            <div class="form-group">
              <label for="severity">Effort</label>
              <select class="form-control" id="effort" v-model="newFinding.effort">
                <option value="0">None</option>
                <option value="1">High</option>
                <option value="2">Mid</option>
                <option value="3">Low</option>
              </select>
            </div>

            <div class="form-group">
              <label for="exampleFormControlTextarea1">Finding</label>
              <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"
                        v-model="newFinding.finding"></textarea>
            </div>

            <div class="form-group">
              <label for="exampleFormControlTextarea2">Recommendation</label>
              <textarea class="form-control" id="exampleFormControlTextarea2" rows="3"
                        v-model="newFinding.recommendation"></textarea>
            </div>
            <button type="button" class="btn btn-info btn-block" @click="update()">Update Finding</button>
          </form>
          <div v-else>
            <blockquote class="blockquote text-center">
              <p class="mb-0">Please select a pin to edit the finding.</p>
              <footer class="blockquote-footer">The dots can overlap</footer>
            </blockquote>
          </div>
        </div>
      </div>
      <div :class="{ 'col-12': isOwner(), 'col-12 col-md-8': !isOwner()}">
        <div class="card">
          <h2 class="font-weight-bold">Findings</h2>
          <table class="table table-striped table-responsive">
            <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Category</th>
              <th scope="col">Severity of Issue</th>
              <th scope="col">Effort to Implement</th>
              <th scope="col">Type</th>
              <th scope="col">Finding</th>
              <th scope="col">Recommendation</th>
              <th scope="col">Date</th>
              <th scope="col" v-if="isOwner()"></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(finding,index) in findings" :key="'finding-'+index" @click="selectedFindingIndex = index"
                :class="{'active': selectedFindingIndex === index}">
              <th scope="row">{{ index + 1 }}</th>
              <td>{{ finding.criteria.title }}</td>
              <td>{{ getRating(finding.severity) }}</td>
              <td>{{ getRating(finding.effort) }}</td>
              <td>{{ Number(finding.is_good) ? 'Good' : 'Bad' }}</td>
              <td>{{ finding.finding }}</td>
              <td>{{ finding.recommendation }}</td>
              <td>{{ finding.created_at | moment('calendar') }}</td>
              <td v-if="isOwner()">
                <button class="btn btn-danger btn-sm" @click="deleteItem(finding.id)">Delete</button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


import ApiService from "../services/api";

export default {
  name: "screen",
  data() {
    return {
      screensPath: window.screensPath,
      ready: false,
      newModal: false,
      hidePins: false,
      screen: {},
      showNewFinding: false,
      showEditFinding: false,
      emptyFinding: {
        axis_x: 0,
        axis_y: 0,
        criteria_id: 1,
        is_good: 1,
        severity: 1,
        effort: 0,
        finding: '',
        recommendation: ''
      },
      newFinding: {
        axis_x: 0,
        axis_y: 0,
        criteria_id: 1,
        is_good: 1,
        severity: 1,
        effort: 0,
        finding: '',
        recommendation: ''
      },
      findings: [],
      projectId: this.$route.params.projectId,
      flowId: this.$route.params.flowId,
      screenId: this.$route.params.screenId,
      selectedFindingIndex: 0,
      categories: [
        {
          id: 1,
          value: "Accessibility"
        }, {
          id: 2,
          value: "Consistency & Standards"
        }, {
          id: 3,
          value: "Content Quality"
        }, {
          id: 4,
          value: "Ergonomics"
        }, {
          id: 5,
          value: "Error Prevention & Error Tolerance"
        }, {
          id: 6,
          value: "Familiarity"
        }, {
          id: 7,
          value: "Flexibility"
        }, {
          id: 8,
          value: "Learnability"
        }, {
          id: 9,
          value: "Navigation"
        }, {
          id: 10,
          value: "Task Orientation"
        }, {
          id: 11,
          value: "Trust & Credibility"
        }, {
          id: 12,
          value: "Visual Clarity"
        }
      ]
    }
  },
  mounted () {
    this.$gtag.pageview('screen_detail')
  },
  computed: {
    user() {
      return this.$store.getters['user'].data
    },
    selectedFinding() {
      return this.findings[this.selectedFindingIndex];
    }
  },
  methods: {
    isOwner() {
      return this.user.permissions[this.projectId]
    },
    getData() {
      const self = this;
      const $route = self.$route;
      const projectId = $route.params.projectId;
      const flowId = $route.params.flowId;
      const screenId = $route.params.screenId;
      self.$store.commit('SET_LOADING', true)
      ApiService.get('project/' + projectId + '/flow/' + flowId + '/screen/' + screenId + '/findings').then((res) => {
        self.ready = true;
        self.findings = res.data.data;
        self.screen = res.data.screen
        this.$store.commit('SET_LOADING', false)
        this.$store.commit('SET_BREADCRUMBS', {project: res.data.project, flow: res.data.flow, screen: res.data.screen})
      })

    },
    clicked(event) {
      const self = this;
      const elm = document.getElementById('findings').getBoundingClientRect();
      self.newFinding = JSON.parse(JSON.stringify(self.emptyFinding));
      if (event.target.closest('.finding')) {
        self.showNewFinding = false;
        self.getActiveFinding();
        return
      }

      const xPos = (event.layerX - 15);
      const yPos = (event.layerY - 15);

      const axis_x = xPos / elm.width * 100;
      const axis_y = yPos / elm.height * 100;


      self.showNewFinding = true;
      self.showEditFinding = false;
      self.newFinding.axis_x = axis_x;
      self.newFinding.axis_y = axis_y;

    },
    createNewFinding: function () {
      const self = this;
      const $route = self.$route;
      const projectId = $route.params.projectId;
      const flowId = $route.params.flowId;
      const screenId = $route.params.screenId;

      self.$store.commit('SET_LOADING', true)

      let params = new FormData();
      params.append('axis_x', self.newFinding.axis_x);
      params.append('axis_y', self.newFinding.axis_y);
      params.append('criteria_id', self.newFinding.criteria_id);
      params.append('is_good', self.newFinding.is_good);
      params.append('severity', self.newFinding.severity);
      params.append('effort', self.newFinding.effort);
      params.append('finding', self.newFinding.finding);
      params.append('recommendation', self.newFinding.recommendation);

      ApiService.post('project/' + projectId + '/flow/' + flowId + '/screen/' + screenId + '/finding', params).then(() => {
        self.newFinding = JSON.parse(JSON.stringify(self.emptyFinding));
        self.getData();
        self.showNewFinding = false;
        self.$gtag.event('create', { method: 'finding' })
      }).catch((error) => {
        self.$swal.fire({
          html: '<br/>' + error.response.data.error,
        })
        self.$store.commit('SET_LOADING', false)
      })

    },
    getCategoryName(id) {
      const allCategories = this.categories;
      return allCategories.find((item) => item.id === parseInt(id)).value
    },
    getRating(id) {
      id = parseInt(id);
      let severityStr = '';
      if (id === 0) severityStr = 'None';
      if (id === 1) severityStr = 'High';
      if (id === 2) severityStr = 'Mid';
      if (id === 3) severityStr = 'Low';
      return severityStr;
    },
    getActiveFinding() {
      const self = this;
      self.showEditFinding = true;
      self.newFinding = JSON.parse(JSON.stringify(self.findings[self.selectedFindingIndex]));
    },
    update() {

      const self = this;
      const $route = self.$route;
      const projectId = $route.params.projectId;
      const flowId = $route.params.flowId;
      const screenId = $route.params.screenId;
      const findingId = self.selectedFinding.id;

      self.$store.commit('SET_LOADING', true)

      let params = new FormData();
      params.append('axis_x', self.newFinding.axis_x);
      params.append('axis_y', self.newFinding.axis_y);
      params.append('criteria_id', self.newFinding.criteria_id);
      params.append('is_good', self.newFinding.is_good === 'true' ? 1 : 0);
      params.append('severity', self.newFinding.severity);
      params.append('effort', self.newFinding.effort);
      params.append('finding', self.newFinding.finding);
      params.append('recommendation', self.newFinding.recommendation);

      ApiService.post('project/' + projectId + '/flow/' + flowId + '/screen/' + screenId + '/finding/' + findingId, params).then(() => {
        self.getData();
        self.newFinding = JSON.parse(JSON.stringify(self.emptyFinding));
        self.showEditFinding = false;
        self.$gtag.event('update', { method: 'finding' })
      }).catch((error) => {
        self.$swal.fire({
          html: '<br/>' + error.response.data.error,
        })
        self.$store.commit('SET_LOADING', false)
      })

    },
    deleteItem(id) {
      const self = this;
      self.$swal.fire({
        title: 'Are you sure?',
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          self.$store.commit('SET_LOADING', true)
          ApiService.delete('project/' + self.$route.params.projectId + '/flow/' + self.$route.params.flowId + '/screen/' + self.$route.params.screenId + '/finding/' + id).then(() => {
            self.getData();
            self.$gtag.event('delete', { method: 'finding' })
          })
        }
      })

    },
  },
  created() {
    const self = this;
    self.getData();
  },

}
</script>

<style scoped lang="scss">
.screen {
  margin-left: 10px;

  .parent {
    position: relative;
  }

  .image {
    display: inline-block;

    img {
      width: 100%;
    }
  }

  .findings {
    width: 100%;
    min-height: 100%;
    display: flex;
    overflow: hidden;
    border-radius: 7px;
    touch-action: none;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;

    .finding {
      position: absolute;
      background: #fff;
      color: #000;
      font-weight: bold;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 1px solid rgba(0, 0, 0, 0.2);
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 0 0 rgba(0, 0, 0, 0.4);

      &:hover {
        cursor: pointer;
      }

      &.active {
        animation: pulse 1s infinite;
      }
    }
  }


  .new-finding-pin {
    position: absolute;
    background-color: #6cc800;
    color: #000;
    font-weight: bold;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 3px solid rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.4);
    z-index: 99;
    animation: pulse 1s infinite;
  }


}

.table {
  font-size: 13px;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.hide_pin {
  position: absolute;
  right: 15px;
  top: 15px;
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-end;
}
</style>
