<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <div class="card">
          <div class="card-header">Login</div>
          <div class="card-body">
            <div v-if="error" class="alert alert-danger">{{ error }}</div>
            <form action="#" @submit.prevent="submit">
              <div class="form-group row">
                <label for="email" class="col-md-4 col-form-label text-md-right">Email</label>

                <div class="col-md-6">
                  <input
                      id="email"
                      type="email"
                      class="form-control"
                      name="email"
                      value
                      required
                      autofocus
                      v-model="form.email"
                  />
                </div>
              </div>

              <div class="form-group row">
                <label for="password" class="col-md-4 col-form-label text-md-right">Password</label>

                <div class="col-md-6">
                  <input
                      id="password"
                      type="password"
                      class="form-control"
                      name="password"
                      required
                      v-model="form.password"
                  />
                </div>
              </div>

              <div class="form-group row">
                <div class="col-md-10 offset-md-4">
                  <button type="submit" class="btn btn-primary">Login</button>
                </div>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


import ApiService from "../services/api";
import JwtService from "../services/jwt";

export default {
  data() {
    return {
      form: {
        email: "",
        password: ""
      },
      error: null
    };
  },
  mounted () {
    this.$gtag.pageview('login')
  },
  methods: {
    submit() {
      const self = this;
      self.$store.commit('SET_LOADING', true)
      ApiService.post('login', self.form).then((res) => {
        console.log(res)
        const token = res.data.token
        JwtService.saveToken(token)
        let goTo = '/'
        if (this.$route.query.redirect) {
          goTo = this.$route.query.redirect;
        }
        window.location = goTo
      })
          .catch((err) => {
            console.log(err)
          })
          .then(() => {
            this.$store.commit('SET_LOADING', false)
          })
    }
  },
  created() {
    this.$store.commit('SET_LOADING', false)
  }
};
</script>
