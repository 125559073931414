<template>
    <div v-if="user">
        <ul>
            <li v-for="(doc,index) in documents" v-bind:key="index">{{doc}}</li>
        </ul>
    </div>

</template>
<script>

    import { mapGetters } from "vuex";
    export default {
        data() {
            return {
                documents:[],
            }
        },
        computed: {
            ...mapGetters({
                user: "user"
            })
        },

    };
</script>
