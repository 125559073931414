<template>
  <nav id="nav">
    <div class="logo">
      <router-link to="/">
        <img src="../assets/gingain.png" class="logo__img"/>
      </router-link>
    </div>
    <div class="breadcrumbs d-none d-md-block" v-if="user.loggedIn">
      <router-link to="/">Dashboard</router-link>
      <router-link :to="'/project/'+breadcrumbs.project.id" v-if="breadcrumbs.project">{{ breadcrumbs.project.title }}
      </router-link>
      <router-link :to="'/project/'+breadcrumbs.project.id+'/flow/'+ breadcrumbs.flow.id" v-if="breadcrumbs.flow">
        {{ breadcrumbs.flow.title }}
      </router-link>
      <router-link
          :to="'/project/'+breadcrumbs.project.id+'/flow/'+ breadcrumbs.flow.id+ '/screen/'+ breadcrumbs.screen.id"
          v-if="breadcrumbs.screen">{{ breadcrumbs.screen.title }}
      </router-link>
    </div>
    <div class="show-report" v-if="breadcrumbs.project">
      <router-link :to="'/report/'+breadcrumbs.project.id" class="btn btn-sm btn-block btn-primary">Show Report
      </router-link>
    </div>
    <div class="user">
      <template v-if="user.loggedIn">
        <div class="name">Hi, {{ user.data.name }}</div>
        <button class="btn btn-danger btn-sm" @click.prevent="signOut">LOGOUT</button>
      </template>
    </div>
  </nav>
</template>
<style lang="scss">
.btn-danger {
  background-color: #dc3545;
}

#nav {
  background-color: #fff;
  height: 70px;
  padding: 0 20px;
  box-shadow: 1px 0px 3px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .logo {
    text-align: left;

    &__img {
      height: 50px;
    }

    a {
      color: #384155;
    }
  }

  .show-report {
    margin: 0 auto;
  }

  .user {
    display: flex;
    justify-content: flex-end;
    text-align: right;
    font-size: 17px;
    margin-left: auto;
    align-items: center;

    .name {
      margin-right: 10px;
    }

    a {
      margin: 0 0 0 15px;
      cursor: pointer;
      color: #384155;
    }
  }
}

.breadcrumbs {
  border-left: 1px solid #9d9d9d;
  margin-left: 20px;
  padding-left: 20px;

  a {
    color: #7954f3;
    display: inline-block;
    margin-right: 10px;
    font-size: 16px;
    font-weight: 600;

    &:after {
      content: '→';
      display: inline-block;
      margin-left: 5px;
      font-size: 13px;
    }

    &:last-child {
      color: #747474;

      &:after {
        content: ''
      }

    }
  }
}
</style>
<script>
import {mapGetters} from "vuex";


export default {
  computed: {
    ...mapGetters({
      user: "user",
      breadcrumbs: 'breadcrumbs'
    })
  },
  methods: {
    signOut() {
      window.localStorage.clear();
      window.location = '/'
    }
  },
  created() {
    this.$store.commit('SET_LOADING', true)
  }
};
</script>
