import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.use(require('vue-moment'));

import VueSweetalert2 from 'vue-sweetalert2';

import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2);

import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
    config: { id: "G-ECNJN7D78J" }
});

import '@/assets/stylesheets/app.scss';
import '@/assets/javascripts/app.js';

import ApiService from "./services/api";

window.screensPath = 'https://uxrapi.gingain.com/storage/screens/';
//window.screensPath = 'http://review.local/storage/screens/';
const getOrderBy = window.localStorage.getItem('order_by');
if (!getOrderBy) {
    window.localStorage.setItem('order_by', 'desc');
}
ApiService.init();

Vue.config.productionTip = false

window.token = localStorage.getItem('token');

function checkLogin() {
    const token = localStorage.getItem('token')
    if (token) {
        return true
    } else {
        localStorage.clear();
        return false
    }
}

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth) && !checkLogin()) {
        next({path: '/login', query: {redirect: to.fullPath}});
    } else {
        next();
    }
});

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
