<template>
  <div v-if="ready">
    <div class="report">

      <div class="nav-header">
        <ul class="nav nav-tabs">
          <li class="nav-item" @click="currentTab='general'">
            <span class="nav-link" :class="{active : currentTab==='general'}">General Stats</span>
          </li>
          <li class="nav-item" @click="currentTab='category'">
            <span class="nav-link" :class="{active : currentTab==='category'}">Usability Criteria</span>
          </li>
          <li class="nav-item" @click="currentTab='list'">
            <span class="nav-link" :class="{active : currentTab==='list'}">Findings</span>
          </li>
          <li class="nav-item d-none" @click="currentTab='table'">
            <span class="nav-link" :class="{active : currentTab==='table'}">All Findings</span>
          </li>
        </ul>
      </div>

      <div class="body">
        <template v-if="currentTab === 'general'">
          <div class="row">
            <div class="col-12 col-md-9">
              <div class="row">
                <div class="col-12 col-md-6 col-lg-4 ">
                  <div class="card card-stats mb-xl-0">
                    <div class="card-body">
                      <div class="row">
                        <div class="col">
                          <span class="h2 font-weight-bold mb-0">{{ flows().length }} Flow</span>
                          <h5 class="card-title text-uppercase text-muted mb-0">Total Unique Flow</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                  <div class="card card-stats mb-xl-0">
                    <div class="card-body">
                      <div class="row">
                        <div class="col">
                          <span class="h2 font-weight-bold mb-0">{{ screens().length }} Screen</span>
                          <h5 class="card-title text-uppercase text-muted mb-0">Total Unique Screen</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                  <div class="card card-stats mb-xl-0">
                    <div class="card-body">
                      <div class="row">
                        <div class="col">
                          <span class="h2 font-weight-bold mb-0">{{ findings().length }} Finding</span>
                          <h5 class="card-title text-uppercase text-muted mb-0">Total Unique Finding</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                  <div class="card card-stats mb-xl-0 cursor-pointer" @click="currentTab='list'">
                    <div class="card-body">
                      <div class="row">
                        <div class="col">
                          <span class="h2 font-weight-bold mb-0 text-danger">{{ negatives().length }} Negative</span>
                          <h5 class="card-title text-uppercase text-muted mb-0">Total Negative Finding</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                  <div class="card card-stats mb-xl-0 cursor-pointer" @click="currentTab='list'">
                    <div class="card-body">
                      <div class="row">
                        <div class="col">
                          <span class="h2 font-weight-bold mb-0 text-success">{{ positives().length }} Positive</span>
                          <h5 class="card-title text-uppercase text-muted mb-0">Total Positive Finding</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                  <div class="card card-stats mb-xl-0 cursor-pointer" @click="currentTab='list'">
                    <div class="card-body">
                      <div class="row">
                        <div class="col">
                    <span class="h2 font-weight-bold mb-0 text-primary">{{
                        recommendations().length
                      }} Recommendations</span>
                          <h5 class="card-title text-uppercase text-muted mb-0">Total Unique Recommendations</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                  <div class="card card-stats mb-xl-0">
                    <div class="card-body">
                      <div class="row">
                        <div class="col">
                          <span class="h2 font-weight-bold mb-0">{{ groupBy(findings(), 'severity', 1).length }} </span>
                          <h5 class="card-title text-uppercase text-muted mb-0"><span class="text-danger">↑</span> High
                            Level
                            Findings</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                  <div class="card card-stats mb-xl-0">
                    <div class="card-body">
                      <div class="row">
                        <div class="col">
                          <span class="h2 font-weight-bold mb-0">{{ groupBy(findings(), 'severity', 2).length }} </span>
                          <h5 class="card-title text-uppercase text-muted mb-0"><span class="text-warning">↔</span> Mid
                            Level
                            Findings</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                  <div class="card card-stats mb-xl-0">
                    <div class="card-body">
                      <div class="row">
                        <div class="col">
                          <span class="h2 font-weight-bold mb-0">{{ groupBy(findings(), 'severity', 3).length }} </span>
                          <h5 class="card-title text-uppercase text-muted mb-0"><span class="text-info">↓</span> Low
                            Level
                            Findings</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div class="card">
                <div class="card-title">By Priorities</div>
                <div class="card-body p-0">
                  <Pie :chart-data="severityChartData" :chart-options="chartOptions"/>
                </div>
              </div>
            </div>
          </div>

        </template>
        <template v-if="currentTab === 'category'">
          <div class="mt-4">
            <select class="form-control" v-model="severityGroup">
              <option value="0">Show All</option>
              <option value="1">High Level</option>
              <option value="2">Mid Level</option>
              <option value="3">Low Level</option>
            </select>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="card">
                <div class="card-title">Criteria</div>
                <div class="card-body">
                  <Pie :chart-data="criteriaChartData" :chart-options="chartOptions"/>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="row">
                <div class="col-12 col-md-6 col-lg-6" v-for="item in criterion" :key="item.id">
                  <div class="card card-stats mb-xl-0">
                    <div class="card-body">
                      <div class="row">
                        <div class="col">
          <span class="h2 font-weight-bold mb-0">{{
              groupBy(findingsFilterBySeverity(), 'criteria_id', item.id).length
            }} </span>
                          <h5 class="card-title text-uppercase text-muted mb-0">{{ item.value }}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">

          </div>
        </template>
        <template v-if="currentTab === 'list'">
          <div class="select-flow">
            <select class="form-control" v-model="currentFlow">
              <option value="all">Show All</option>
              <option :value="item.id" v-for="item in arrayOrderByAtoZ(flows(), 'title')" :key="item.id">{{
                  item.title
                }}
              </option>
            </select>
          </div>
          <div class="flows">
            <div class="flow" v-for="flow in report.flows" :key="flow.id">
              <template v-if="currentFlow==='all' ||currentFlow === flow.id">
                <div class="flow__title"><span class="text-default">Flow Name</span>: {{ flow.title }}</div>
                <div class="screens">
                  <div class="screen" v-for="screen in flow.screens" :key="screen.id" :ref="'screen-'+screen.id" v-show="screen.findings.length>0">
                    <span class="btn btn-info btn-sm  download-screen" @click="getScreenShot(screen.id, screen.title)" v-if="!loading">Png Download</span>
                    <span class="watermark" v-if="loading"><img src="../assets/gingain.png" /></span>
                    <div class="screen__title">{{ screen.title }}</div>
                    <div class="screen-and-finding">
                      <div class="screen__image"><img :src="screensPath + screen.image" :ref="'screen-image-'+screen.id"
                                                      alt="" class="screen__img">
                      </div>
                      <div class="findings">
                        <div class="finding__group negative"
                             v-if=" groupBy(screen.findings, 'is_good', false).length> 0">
                          <div class="finding__group__title">Negative</div>
                          <ul class="finding__group__list">
                            <li v-for="item in groupBy(screen.findings, 'is_good', false)" :key="item.id">
                              <div>
                                {{ item.finding }} <span
                                  :class="'severity ' + severitys[item.severity].toLowerCase()">{{
                                  severitys[item.severity]
                                }} Level</span>
                                <span class="criteria">{{getCriteriaTitle(item.criteria_id) }}</span>
                              </div>

                            </li>
                          </ul>
                        </div>
                        <div class="finding__group positive"
                             v-if=" groupBy(screen.findings, 'is_good', true).length> 0">
                          <div class="finding__group__title">Positive</div>
                          <ul class="finding__group__list">
                            <li v-for="item in groupBy(screen.findings, 'is_good', true)" :key="item.id">
                              <div>
                                {{ item.finding }}
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div class="finding__group recommendation"
                             v-if="mapBy(screen.findings, 'recommendation').length> 0">
                          <div class="finding__group__title">Recommendation</div>
                          <ul class="finding__group__list">
                            <li v-for="(item, index) in mapBy(screen.findings, 'recommendation')" :key="index">
                              <div>
                                {{ item.recommendation }} <span
                                  :class="'effort ' + efforts[item.effort].toLowerCase()">{{
                                  efforts[item.effort]
                                }} Effort</span>
                              </div>

                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </template>
        <template v-if="currentTab === 'table'">
          Table
        </template>

      </div>
      <div class="footer"></div>
    </div>
  </div>

</template>
<script>
import {mapGetters} from "vuex";
import ApiService from "../services/api";

import {Pie} from 'vue-chartjs/legacy'
import {Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement)

import html2canvas from 'html2canvas';

export default {
  data() {
    return {
      ready: false,
      currentTab: 'general',
      currentFlow: 'all',
      screensPath: window.screensPath,
      report: {},
      criterion: [
        {
          id: 1,
          value: "Accessibility"
        }, {
          id: 2,
          value: "Consistency & Standards"
        }, {
          id: 3,
          value: "Content Quality"
        }, {
          id: 4,
          value: "Ergonomics"
        }, {
          id: 5,
          value: "Error Prevention & Error Tolerance"
        }, {
          id: 6,
          value: "Familiarity"
        }, {
          id: 7,
          value: "Flexibility"
        }, {
          id: 8,
          value: "Learnability"
        }, {
          id: 9,
          value: "Navigation"
        }, {
          id: 10,
          value: "Task Orientation"
        }, {
          id: 11,
          value: "Trust & Credibility"
        }, {
          id: 12,
          value: "Visual Clarity"
        }
      ],
      severitys: {
        1: 'High',
        2: 'Mid',
        3: 'Low',
      },
      efforts: {
        0: 'No',
        1: 'High',
        2: 'Mid',
        3: 'Low',
      },
      severityChartData: {},
      criteriaChartData: {},
      chartOptions: {
        responsive: true
      },
      severityGroup: 0,

    }
  },
  components: {
    Pie
  },
  mounted() {
    this.$gtag.pageview('report_showing');
  },
  computed: {
    ...mapGetters({
      user: "user",
      loading:"loading"
    }),
  },
  methods: {
    findingsFilterBySeverity() {
      if (this.severityGroup > 0) {
        return this.findings().filter(finding => parseInt(finding.severity) === parseInt(this.severityGroup))
      } else {
        return this.findings();
      }

    },
    flows() {
      return this.report.flows.map(item => ({id: item.id, title: item.title}));
    },
    screens() {
      let screens = [];
      this.report.flows.map((item) => {
        if (item.screens) {
          item.screens.reduce(function (r, e) {
            screens.push({id: e.id, title: e.title, flow_id: e.flow_id, image: e.image});
            return r;
          }, []);
        }
      });
      return screens;
    },
    findings() {
      let findings = [];
      let screens = [];
      this.report.flows.map((item) => {
        if (item.screens) {
          item.screens.reduce(function (r, e) {
            screens.push(e);
            return r;
          }, []);
        }
      });

      screens.map((item) => {
        if (item.findings) {
          item.findings.reduce(function (r, e) {
            findings.push({
              id: e.id,
              is_good: e.is_good,
              severity: parseInt(e.severity),
              effort: parseInt(e.effort),
              screen_id: parseInt(e.screen_id),
              criteria_id: parseInt(e.criteria_id),
              recommendation: e.recommendation,
            });
            return r;
          }, []);
        }
      });

      return findings;
    },
    negatives() {
      return this.findings().filter(item => item.is_good === false);
    },
    positives() {
      return this.findings().filter(item => item.is_good === true);
    },
    recommendations() {
      return this.findings().filter(item => String(item.recommendation).length > 5);
    },
    async getData() {
      const self = this;
      const $route = self.$route;
      const projectId = $route.params.projectId;
      self.$store.commit('SET_LOADING', true)
      ApiService.get('report/' + projectId).then((res) => {

        self.report = res.data.data
        this.$store.commit('SET_LOADING', false)
        self.ready = true;

        self.severityChartData = {
          labels: [
            'High',
            'Minor',
            'Low'
          ],
          datasets: [{
            label: 'Severity',
            data: [
              self.groupBy(self.findings(), 'severity', 1).length,
              self.groupBy(self.findings(), 'severity', 2).length,
              self.groupBy(self.findings(), 'severity', 3).length],
            backgroundColor: [
              'rgb(255, 99, 132)',
              'rgb(54, 162, 235)',
              'rgb(255, 205, 86)'
            ],

            hoverOffset: 5,
          }]
        }
        self.drawCriteriaChart();
      })

    },
    drawCriteriaChart() {
      const self = this;
      self.criteriaChartData = {
        labels: self.criterion.map(item => item.value),
        datasets: [{
          label: 'Severity',
          data: self.criterion.map(item => {
            return self.groupBy(self.findingsFilterBySeverity(), 'criteria_id', item.id).length
          }),
          backgroundColor: [
            'rgba(252, 92, 101,1.0)',
            'rgba(253, 150, 68,1.0)',
            'rgba(38, 222, 129,1.0)',
            'rgba(43, 203, 186,1.0)',
            'rgba(235, 59, 90,1.0)',
            'rgba(69, 170, 242,1.0)',
            'rgba(56, 103, 214,1.0)',
            'rgba(165, 94, 234,1.0)',
            'rgba(165, 177, 194,1.0)',
            'rgba(75, 101, 132,1.0)',
            'rgba(61, 61, 61,1.0)',
            'rgba(204, 142, 53,1.0)',
            'rgba(44, 44, 84,1.0)',
            'rgba(109, 33, 79,1.0)'
          ],
          hoverOffset: 5,
        }]
      }
    },
    groupBy(arr, selector, value) {
      return arr.filter((item) => item[selector] === value);
    },
    mapBy(arr, selector) {
      return arr.filter((item) => {
        if (JSON.stringify(item[selector]).length > 6) {
          return item[selector];
        }

      });
    },
    lengthInArrayKeyWithValue(array, key, value) {
      return array.reduce((count, obj) => {
        if (obj[key] === value) {
          return count + 1;
        }
        return count;
      }, 0);
    },
    arrayOrderByAtoZ(array, key) {
      return array.sort((a, b) => {
        if (a[key] < b[key]) {
          return -1;
        }
        if (a[key] > b[key]) {
          return 1;
        }
        return 0;
      });
    },
    getScreenShot(id, title) {
      const self = this;
      self.$store.commit('SET_LOADING', true)
      const screen = this.$refs['screen-' + id][0];
      const screenImage = this.$refs['screen-image-' + id][0];
      fetch(screenImage.getAttribute('src'), {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {},
        referrer: 'no-referrer',
      }).then(x => x.blob().then(blob => {
        var reader = new FileReader();
        reader.onload = function () {
          screenImage.setAttribute('src', this.result)
          setTimeout(() => {
            html2canvas(screen, {
              scale: 3
            }).then(function (canvas) {

              let link = document.createElement('a');
              link.href = canvas.toDataURL();
              link.download = title + '.png';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              self.$store.commit('SET_LOADING', false)

            });
          }, 500)
        };
        reader.readAsDataURL(blob);
      }))
    },
    getCriteriaTitle(id) {
      return this.criterion.find(item => item.id === id).value;
    }

  },
  created() {
    const self = this;
    self.getData();
  },
  watch: {
    severityGroup: function () {
      this.$nextTick(() => this.drawCriteriaChart())
    }
  }

};
</script>
<style lang="scss" scoped>
.report {
  h2, .h2 {
    font-size: 18px;
  }

  h5, .h5 {
    font-size: 13px;
  }

  .body {
    padding-top: 40px;
    position: relative;
  }
}

.sub-title {
  margin: 30px 0 0 0;
}

.nav-header {
  position: absolute;
  top: 71px;
  left: 0;
  right: 0;
  background: white;
  padding: 15px 15px 0 15px;
  border-bottom: 1px solid #e0e0e0;
  z-index: 9;

  .nav {
    border-bottom: 0;
    @media (max-width: 767px) {
      flex-wrap: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
    }

    .nav-item {
      white-space: nowrap;
    }

    .nav-link {
      cursor: pointer;
      color: rgba(120, 85, 244, 0.5);
      font-weight: 700;
      position: relative;

      &.active {
        background-color: #f7faff;
        color: #7855f4;

        &:after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          height: 1px;
          width: 100%;
          background-color: #f7fafe;
          bottom: -1px;
          z-index: 9999;
        }
      }
    }
  }
}

.select-flow {
  position: absolute;
  top: 73px;
  right: 0;
  @media (max-width: 991px) {
    position: relative;
    top: 20px;
  }
}

hr {
  margin: 30px 0 20px 0;
  display: inline-block;
  width: 100%;
}

.flows {
  padding-top: 30px;
}

.flow {
  &__title {
    font-family: 'Oswald', sans-serif;
    color: #7954f3;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 22px;

  }
}

.screen-and-finding {
  display: flex;
}

.screen {
  background-color: white;
  padding: 30px;
  margin: 30px 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
  position: relative;
  //box-shadow: rgba(50, 50, 93, 0.1) 0 30px 60px -12px inset, rgba(0, 0, 0, 0.0) 0px 18px 36px -18px inset;
  .download-screen {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 12px;
    cursor: pointer;
  }
  .watermark {
    position: absolute;
    right: 30px;
    top: 30px;
    img {
      height: 50px;
    }
  }

  @media (max-width: 768px) {
    padding: 15px;
  }

  &__title {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 20px;
  }

  &__image {
    @media (max-width: 768px) {
      display: none;
    }
  }

  &__img {
    width: 300px;
    box-shadow: rgba(0, 0, 0, 0.15) 0 13px 27px -5px, rgba(0, 0, 0, 0.2) 0px 8px 16px -8px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 10px;
  }
}

.findings {
  padding: 0 30px;
  @media (max-width: 768px) {
    padding: 0;
  }
}

.finding__group {
  $self: &;

  &__title {
    font-family: 'Oswald', sans-serif;
    color: #7954f3;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 20px;
    margin: 0 0 10px 0;
  }

  &__list {
    @media (max-width: 768px) {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    li {
      margin-bottom: 20px;
      @media (max-width: 768px) {
        padding-bottom: 15px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }

      &:last-child {
        @media (max-width: 768px) {
          border: none;
        }
      }
    }
  }

  &.negative {
    #{$self}__title {
      color: #F24913;
    }
  }

  &.positive {
    #{$self}__title {
      color: #9fcd4b;
    }
  }

  &.recommendation {
    #{$self}__title {
      color: #355CD6;
    }
  }
}

.card-stats {
  @media (max-width: 768px) {
    margin: 20px 0 0 0;
  }

  .card-body {
    padding: 0;
  }
}

.effort {
  font-size: 13px;

  &:before {
    content: '-'
  }
}

.severity {
  display: inline-block;
  color: #fff;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;
  background-color: white;
  padding: 1px 5px;
  position: relative;
  top: -2px;
  text-align: center;

  &.high {
    background-color: rgba(242, 73, 19, 0.75);
  }

  &.mid {
    background-color: rgba(242, 73, 19, 0.5);
  }

  &.low {
    background-color: rgba(242, 73, 19, 0.25);
  }
}
.criteria {
  display: inline-block;
  color: #fff;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;
  background-color: #607d8b;
  padding: 1px 5px;
  position: relative;
  top: -2px;
  text-align: center;
}
</style>
