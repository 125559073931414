<template>
  <div class="order-by-list">
    <select v-model="orderBy" class="form-control">
      <option value="asc">A to Z</option>
      <option value="desc">Z to A</option>
    </select>
  </div>
</template>

<script>


export default {
  name: 'orderBy',
  data() {
    return {
      picture: null,
      uploadValue: 0
    }
  },
  methods: {},
  computed: {
    orderBy: {
      get() {
        return this.$store.getters.orderBy
      },
      set(newName) {
        window.localStorage.setItem('order_by', newName);
        this.$store.commit('SET_ORDER_BY', newName)
      }
    }
  }
}

</script>


<style lang="scss" scoped>
.order-by-list {

  display: flex;
  justify-content: flex-end;

  .form-control {
    width: 100px;
  }
}

</style>
