<template>
  <div id="dashboard">
    <div class="container-fluid ready">
      <div class="list">
        <div class="row">
          <div class="col-12 col-lg-4">
            <div class="item add-new" @click="newModal=true">
              <div v-if="!newModal">
                <span class="create-new">Create new project</span>
              </div>
              <template v-else>
                <form class="form" @submit.prevent="createNewProject()">
                  <div class="form-group">
                    <label for="newname">Project name</label>
                    <input type="text" class="form-control" id="newname" v-model="newTitle">
                    <small class="form-text text-muted">Please choose a name for your project.</small>
                  </div>

                  <button type="button" class="btn btn-primary btn-block send" @click="createNewProject()">
                    Create
                  </button>
                </form>
              </template>
            </div>
          </div>
          <div class="col-12 col-lg-4 parent" v-for="(project,index) in projects"
               v-bind:key="index" :data-id="project.id">
            <div class="actions" v-if="isOwner(project.id)">
              <button class="btn btn-info btn-sm" @click="edit(project)">Edit</button>
              <button class="btn btn-danger btn-sm" @click="deleteProject(project.id)">Delete</button>
            </div>
            <div class="item real" @click="goToProject(project.id)">
              <div class="item__title">{{ project.title }}</div>
              <div class="item__summary">
                <div class="item__summary__item"><span class="value">{{ project.flows_count }}</span><span class="text"> FLOWS</span>
                </div>
                <div class="item__summary__item"><span class="value">{{ project.screens_count }}</span><span
                    class="text"> SCREENS</span></div>
              </div>
              <div class="item__date"> {{ project.created_at | moment('calendar') }}</div>
            </div>
            <span class="report-button" v-if="project.screens_count > 0"><router-link :to="'/report/'+project.id" class="btn btn-sm btn-block btn-primary">Show Report</router-link></span>
          </div>
        </div>
      </div>
      <div class="modal" tabindex="-1" v-if="showEditModal">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Edit Title</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="showEditModal = false">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="form-group">
                <input type="text" class="form-control"  v-model="newTitle">
                <small class="form-text text-muted">Please choose title.</small>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="showEditModal = false" >Close</button>
              <button type="button" class="btn btn-primary" @click="update()">Save changes</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.modal {
  display: block!important;
  background-color:rgba(0,0,0,0.5)
}
.page-header {
  width: 100%;
  background-color: #fff;
  box-shadow: rgba(46, 91, 255, 0.07) 0 3px 6px 0;
  display: flex;
  margin: 10px;
  position: relative;
  border-radius: 5px;
  color: black;
  text-align: center;
  height: auto;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px;
}

.list {

  .item {
    height: 200px;
    padding: 0 20px;
    margin-bottom: 20px;
    background-color: #fff;
    box-shadow: rgba(46, 91, 255, 0.07) 0 3px 6px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 5px;
    color: black;
    text-align: center;
    cursor: pointer;

    &.real {
      flex-direction: column;
    }

    .delete {
      position: absolute;
      right: 10px;
      top: 10px;
      font-size: 10px;
      cursor: pointer;
    }

    &__title {
      color: #7954f3;
      font-weight: 600;
      text-transform: uppercase;
      font-size: 22px;
    }

    &__summary {
      display: flex;
      font-size: 15px;

      &__item {
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }
      }

      .value {
        display: inline-block;
        margin-right: 5px;
      }

      .text {
        display: inline-block;
      }
    }

    &__date {
      font-size: 13px;
      color: rgba(0, 0, 0, 0.5);
    }
  }

  .header {

    height: auto;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    padding: 30px;
  }

  .add-new {

    .create-new {
      display: block;
      padding: 30px;
      cursor: pointer;
      font-weight: 100;

    }
  }

  .actions {
    display: none;
    position: absolute;
    z-index: 1;
    top: 15px;
    right: 30px;

    .btn {
      margin-left: 10px;
    }
  }

  .report-button {
    display: none;
    position: absolute;
    top: 15px;
    left: 30px;
    @media (max-width: 768px) {
      display: block;
    }
  }

  .parent {
    &:hover {
      .actions {
        display: block;
      }
      .report-button {
        display: block;
      }
    }
  }
}

.form {
  text-align: left;
}


</style>
<script>

import {mapGetters} from "vuex";
import ApiService from "../services/api";

export default {
  name: 'Home',
  data() {
    return {
      newModal: false,
      showEditModal:false,
      selectedItemId: '',
      newTitle: '',
      projects: [],
    }
  },
  mounted () {
    this.$gtag.pageview('dashboard')
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  methods: {
    isOwner(projectId) {
      return this.user.data.permissions[projectId]
    },
    async getData() {
      const self = this;
      self.$store.commit('SET_LOADING', true)
      ApiService.get('projects').then((res) => {
        self.projects = res.data.data;
        self.$store.commit('SET_LOADING', false)
        self.$store.commit('SET_BREADCRUMBS', {})
      })

    },
    createNewProject() {
      const self = this;
      const title = self.newTitle;

      self.$store.commit('SET_LOADING', true)

      const params = new URLSearchParams();
      params.append('title', title);

      ApiService.post('project', params).then(() => {
        self.getData();
        self.newTitle = '';
        self.newModal = false;
        self.$store.dispatch('fetchUser');
        self.$gtag.event('create', { method: 'project' })
      }).catch((error)=> {
        self.$swal.fire({
          html: '<br/>'+error.response.data.error,
        })
        self.$store.commit('SET_LOADING', false)
      })


    },
    goToProject(id) {
      const self = this;
      self.$router.push('/project/' + id)
    },
    deleteProject(id) {
      const self = this;
      self.$swal.fire({
        title: 'Are you sure?',
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          self.$store.commit('SET_LOADING', true)
          ApiService.delete('project/' + id).then(() => {
            self.$gtag.event('delete', { method: 'project' })
            self.getData();
          })
        }
      })

    },
    edit(project){
      this.showEditModal = true
      this.newTitle = project.title;
      this.selectedItemId = project.id;
    },
    update(){
      const self = this;
      const params = new URLSearchParams();
      params.append('title', self.newTitle);
      self.$store.commit('SET_LOADING', true)
      ApiService.post('project/'+self.selectedItemId, params).then(() => {
        self.getData();
        self.newTitle = '';
        self.showEditModal = false;
        self.selectedItemId = null;
        self.$store.commit('SET_LOADING', false)
        self.$gtag.event('update', { method: 'project' })
      })
    }
  },
  created() {

    this.getData()


  }
}
</script>
