import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

import Login from '../views/Login'
import Register from '../views/Register'
import Dashboard from '../views/Dashboard'

import Report from '../views/Report'
import Project from '../views/Project'
import Flow from '../views/Flow'
import Screen from '../views/Screen'


Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/register',
    name: 'register',
    component: Register
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/report/:projectId',
    name: 'report',
    component: Report,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/project/:projectId',
    name: 'project',
    component: Project,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/project/:projectId/flow/:flowId',
    name: 'flow',
    component: Flow,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/project/:projectId/flow/:flowId/screen/:screenId',
    name: 'screen',
    component: Screen,
    meta: {
      requiresAuth: true
    }
  }
];

const router = new VueRouter({
  mode: "history",
  routes
});

export default router
