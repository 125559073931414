import Vue from "vue";
import Vuex from "vuex";


Vue.use(Vuex);

import ApiService from "../services/api";

export default new Vuex.Store({
    state: {
        loading: true,
        breadcrumbs: {},
        orderBy: localStorage.getItem('order_by') || 'desc',
        user: {
            loggedIn: false,
            data: null
        },
        current: {
            project: null,
            flow: null,
            screen: null,
        }
    },
    getters: {
        user(state) {
            return state.user
        },
        loading(state) {
            return state.loading
        },
        current(state) {
            return state.current
        },
        breadcrumbs(state) {
            return state.breadcrumbs
        },
        orderBy(state) {
            return state.orderBy
        },
    },
    mutations: {
        SET_LOADING(state, value) {
            state.loading = value;
        },
        SET_LOGGED_IN(state, value) {
            state.user.loggedIn = value;
        },
        SET_USER(state, data) {
            state.user.data = data;
        },
        SET_CURRENT(state, data) {
            state.current = {...state.current, data};
        },
        SET_BREADCRUMBS(state, data) {
            state.breadcrumbs = data;
        },
        SET_ORDER_BY(state, data) {
            state.orderBy = data;
        },
    },
    actions: {
        async fetchUser({commit}) {
            ApiService.get('me').then((res) => {
                commit("SET_USER", res.data);
                commit("SET_LOGGED_IN", true);
            })
        },
        fetchCurrentProject({commit}, currentProject) {
            commit("SET_CURRENT_PROJECT", currentProject);
        }
    }
});
