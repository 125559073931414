<template>
  <div class="page">

    <div v-if="ready" class="list container-fluid">

      <div class="row">
        <div class="col-12 col-lg-4" v-if="isOwner()">
          <div class="item add-new">
            <div v-if="!newModal" @click="newModal=true">
              <span class="create-new">Create new flow</span>
            </div>
            <template v-else>
              <form class="form"  @submit.prevent="createNew()">
                <div class="form-group">
                  <label for="newname">Flow name</label>
                  <input type="text" class="form-control" id="newname" v-model="newTitle">
                  <small class="form-text text-muted">Please choose a name for your flow.
                  </small>
                </div>

                <button type="button" class="btn btn-primary btn-block send" @click="createNew()">
                  Create
                </button>
              </form>
            </template>
          </div>
        </div>
        <div class="col-12 col-lg-4 parent" v-for="(flow,index) in flows" v-bind:key="index">
          <div class="actions" v-if="isOwner()">
            <button class="btn btn-info btn-sm" @click="edit(flow)">Edit</button>
            <button class="btn btn-danger btn-sm" @click="deleteItem(flow.id)">Delete</button>
          </div>
          <router-link :to="'/project/'+projectId+'/flow/'+flow.id" class="item">
            <div class="text">
              <div class="item__title">{{ flow.title }}</div>
              <div class="item__date">{{ flow.created_at | moment('calendar') }}</div>
            </div>
          </router-link>
        </div>
      </div>
      <div class="modal" tabindex="-1" v-if="showEditModal">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Edit Title</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                      @click="showEditModal = false">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="form-group">
                <input type="text" class="form-control" v-model="newTitle">
                <small class="form-text text-muted">Please choose title.</small>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="showEditModal = false">
                Close
              </button>
              <button type="button" class="btn btn-primary" @click="update()">Save changes</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import ApiService from "../services/api";

export default {
  name: "project",
  components: {},
  data() {
    return {
      ready: false,
      newModal: false,
      projectId: this.$route.params.projectId,
      newTitle: '',
      project: {},
      flows: [],
      showEditModal: false,
      selectedItemId: '',
    }
  },
  mounted () {
    this.$gtag.pageview('project_detail')
  },
  computed: {
    user() {
      return this.$store.getters['user'].data
    }
  },
  methods: {
    isOwner() {
      return this.user.permissions[this.projectId]
    },
    async getData() {
      const self = this;
      const $route = self.$route;
      const projectId = $route.params.projectId;
      self.$store.commit('SET_LOADING', true)
      ApiService.get('project/' + projectId + '/flows').then((res) => {
        self.ready = true;
        self.flows = res.data.data
        self.project = res.data.project
        this.$store.commit('SET_LOADING', false)
        this.$store.commit('SET_BREADCRUMBS', {project: res.data.project})
      })

    },
    createNew() {
      const self = this;
      const $route = self.$route;
      const projectId = $route.params.projectId;
      const title = self.newTitle;

      self.$store.commit('SET_LOADING', true)

      const params = new URLSearchParams();
      params.append('title', title);

      ApiService.post('project/' + projectId + '/flow', params).then(() => {
        self.getData();
        self.newModal = false;
        self.newTitle = '';
        self.$gtag.event('create', { method: 'flow' })
      }).catch((error)=> {
        self.$swal.fire({
          html: '<br/>'+error.response.data.error,
        })
        self.$store.commit('SET_LOADING', false)
      })

    },
    deleteItem(id) {
      const self = this;
      self.$swal.fire({
        title: 'Are you sure?',
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          self.$store.commit('SET_LOADING', true)
          ApiService.delete('project/' + self.$route.params.projectId + '/flow/' + id).then(() => {
            self.getData();
            self.$gtag.event('delete', { method: 'flow' })
          })
        }
      })

    },
    edit(item) {
      this.showEditModal = true
      this.newTitle = item.title;
      this.selectedItemId = item.id;
    },
    update() {
      const self = this;
      const params = new URLSearchParams();
      params.append('title', self.newTitle);
      self.$store.commit('SET_LOADING', true)
      ApiService.post('project/' + self.$route.params.projectId + '/flow/' + self.selectedItemId, params).then(() => {
        self.getData();
        self.newTitle = '';
        self.showEditModal = false;
        self.selectedItemId = null;
        self.$store.commit('SET_LOADING', false)
        self.$gtag.event('update', { method: 'flow' })
      }).catch((error)=> {
        self.$swal.fire({
          html: '<br/>'+error.response.data.error,
        })
        self.$store.commit('SET_LOADING', false)
      })
    }
  },
  created() {
    const self = this;
    self.getData();
  }
}
</script>

<style scoped>

</style>
